.logo

  span
    display block
    margin-bottom 10px
    text-transform uppercase
    &:last-child
      margin-bottom 0

h1
  width 100%
  margin-bottom 30px
  line-height 70px
  font-size 48px
  text-align center
