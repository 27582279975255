.services
  background #f2f2f2
  color #000
  font-family RobotoLight
  padding 60px 0

.services-main
  width 100%
  max-width 800px
  margin 0 auto

.services-main__items
  width 100%
  max-width 800px
  display flex
  margin-bottom 45px
  align-items center
  padding 20px
  background white
  box-shadow: 7.071px 7.071px 35px 7px rgba(0, 0, 0, 0.1)

.criminal-law__photo
  margin-right: 40px

.criminal-law__pic
  width: 220px
  height: 220px
  border-radius: 50%
  overflow hidden
  background url('../img/general/criminal.jpg') 0 0 no-repeat
  background-size: cover


.criminal-law__title
  font-size 25px
  font-weight: 600;

.criminal-law__text
  margin-top: 20px
  line-height: 20px;

//

.civil-law__photo
  margin-right: 40px

.civil-law__pic
  width: 220px
  height: 220px
  border-radius: 50%
  overflow hidden
  background url('../img/general/civil.jpg') -99px 0 no-repeat
  background-size: cover

.civil-law__title
  font-size 25px
  font-weight: 600;

.civil-law__text
  margin-top: 20px
  line-height: 20px;

//

.family-law__photo
  margin-right: 40px

.family-law__pic
  width: 220px
  height: 220px
  border-radius: 50%
  overflow hidden
  background url('../img/general/family.jpg') 0 0 no-repeat
  background-size: cover

.family-law__title
  font-size 25px
  font-weight: 600;

.family-law__text
  margin-top: 20px
  line-height: 20px;
//

.arbitration-law__photo
  margin-right: 40px

.arbitration-law__pic
  width: 220px
  height: 220px
  border-radius: 50%
  overflow hidden
  background url('../img/general/arbitration.jpg') -58px 0 no-repeat
  background-size: cover

.arbitration-law__title
  font-size 25px
  font-weight: 600;

.arbitration-law__text
  margin-top: 20px
  line-height: 20px;
