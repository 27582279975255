footer
  background-color #f1f1f1
  padding 25px 0
  webkit-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.1);
  -webkit-transform: translate3d(0, 0, 0);

.container
    display flex
    justify-content space-between

