.small-section
  padding: 140px 0
  text-align center
  color: #595959

  .container
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

.small-section-title
  font-size: 50px

.small-section-callus
  margin-top: 100px
  font-size 30px

.small-section__phone
  color #daa520
