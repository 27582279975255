@import 'fonts.styl'
@import 'vars.styl'
@import 'libs.styl'
@import 'mixins.styl'

html
	line-height 1
	font-size 18px


body
	font-family RobotoRegular


h1,h2,h3,h4,h5,h6
	margin 0


ul,li
	margin 0
	padding 0
	list-style-type none

p
	margin 0


a
	text-decoration none
	color black

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle


textarea
	resize none


section
	position relative


input,
select,
button
	outline none


*, *:before, *:after
	box-sizing border-box

.container
	position relative
	width 97%
	max-width 1200px
	margin 0 auto

.wrapper
	overflow hidden

// Общие стили
@import 'common_styles.styl'


// МОДУЛИ
// Стили шапки
@import 'modules/header.styl'

// Стили футера
@import 'modules/footer.styl'

// Стили меню
@import 'modules/menu.styl'


// СТРАНИЦЫ
// Стили главной страницы
@import 'pages/index.styl'

// Стили страницы ДОСТИЖЕНИЯ
@import 'pages/acievements.styl'

// Стили страницы ПРАКТИКА
@import 'pages/practice.styl'

// Стили верхнего блока
@import 'sections/advokat.styl'

// Стили блока услуги
@import 'sections/services.styl'



// Стили блока "Мы здесь, чтобы..."
@import 'sections/call.styl'

// Стили блока "Обо мне"
@import 'sections/about.styl'

// Стили блока "Контакты"
@import 'sections/contacts.styl'



// ПРОЧЕЕ
// Стили медиазапросов
@import 'media.styl'