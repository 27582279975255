.about-us
  background #f2f2f2
  font-family RobotoRegular
  padding 60px 0px


.about-us-main__info
  padding 60px
  font-size: 22px;
  line-height: 30px;
  text-align center
  background white
  box-shadow: 7.071px 7.071px 35px 7px rgba(0, 0, 0, 0.1)

.main-text
  margin-top 40px

.notmain-text
  margin-top: 20px


.g-btn
  text-align center
  margin-top: 65px;

.g-btn__achievements
  color: black; /* цвет текста */
  text-decoration: none; /* убирать подчёркивание у ссылок */
  user-select: none; /* убирать выделение текста */
  background: #daa520; /* фон кнопки */
  padding: .7em 1.5em; /* отступ от текста */
  outline: none; /* убирать контур в Mozilla */

.g-btn__achievements:hover
  background: #F1B921; /* при наведении курсора мышки */
.g-btn__achievements:active
  background: #906e11;  /* при нажатии */

.g-btn__practice
  color: black; /* цвет текста */
  text-decoration: none; /* убирать подчёркивание у ссылок */
  user-select: none; /* убирать выделение текста */
  background: #daa520; /* фон кнопки */
  padding: .7em 1.5em; /* отступ от текста */
  outline: none; /* убирать контур в Mozilla */
  margin-left: 30px
  padding: 15px 48px;

.g-btn__practice:hover
  background: #F1B921; /* при наведении курсора мышки */
.g-btn__practice:active
  background: #906e11;  /* при нажатии */