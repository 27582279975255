.achievements-section-title
  margin-top 100px

.achievements-section-pic_1
  display flex

.achievements-pic__1
  width 49%
  height 80%
  order 1


.achievements-pic__2
  width 49%
  height 80%
  order 2

.achievements-pic__3
  width 49%
  height 80%
  order 3

.achievements-pic__4
  width 49%
  height 80%
  order 4

.achievements-pic__5
  width 49%
  height 80%
  order 5

.achievements-pic__6
  width 49%
  height 80%
  order 6

.achievements-pic__7
  width 49%
  height 80%
  order 7
