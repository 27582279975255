header
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* padding: 0px 0; */
  background-color: #daa520;
  -webkit-box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 3;
  opacity: 0.97;
  height: 80px;

  .container
    display flex
    flex-wrap wrap
    justify-content space-between
    align-items center

.main-logo
  width: 87px;
  height: 83px;
  background: url(../img/general/logomain.jpg) 15px 0 no-repeat;
  background-size: 65px;

.header-logo
  /*display inline-block
  vertical-align middle
  font-weight 900
  font-size 25px*/

  display: flex;
  font-weight: 900;
  font-size: 25px;
  align-items: center

.main-nav
  display inline-block
  vertical-align middle

.main-nav__list
  li
    display inline-block
    margin-right 24px
  a
    color #5c5f61
    display: inline-block;
    position: relative;
    text-decoration: none;
    line-height: 30px;
    transition: 1s linear;


  a:hover
    color: black

  a::before
    display: block;
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background-color: black;
    transition: width .2s ease-in-out, left .2s ease-in-out;
    left: 50%;
    bottom: -2px

  a:after
    display: block;
    position: absolute;
    content: "";
    height: 2px;
    width: 0;
    background-color: black;
    transition: width .2s ease-in-out;
    left: 50%;
    bottom: -2px

  a:hover::before
    width: 50%;
    left: 0;

  a:hover::after
    width: 50%

  &.mobile-show
    right: -1.5%
    transition all linear .8s

/*.line
  color black!important
  border-bottom 2px solid black*/

.nav-mobile
  position: absolute
  display none
  right: -1.5%
  bottom: -20px
  top: -20px
  width: 70px
  cursor pointer
  background-color transparent
  transition all linear .5s

  &.active
    background-color #f1f1f1
    transition all linear .5s
    .nav-mobile__line--top
      top 42px
      transform rotate(45deg)
      transition all linear .5s

    .nav-mobile__line--middle
      opacity 0
      transition all linear .5s

    .nav-mobile__line--bottom
      top 42px
      transform rotate(-45deg)
      transition all linear .5s

.nav-mobile__line
  position absolute
  display block
  width: 30px
  height: 2px
  left: 20px
  background-color black

.nav-mobile__line--top
  top 32px
  transition all linear .5s

.nav-mobile__line--middle
  top 42px
  opacity 1
  transition all linear .5s

.nav-mobile__line--bottom
  top 52px
  transition all linear .5s
