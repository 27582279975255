.practice
  background #f1f1f1

.practice-section__content
  margin-top: 100px;
  padding: 60px;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 7.071px 7.071px 35px 7px rgba(0,0,0,0.1);
  box-shadow: 7.071px 7.071px 35px 7px rgba(0,0,0,0.1);
  margin-bottom: 100px;

.main-text-small
  margin-top: 15px;
  font-size: 20px;

