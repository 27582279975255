@media screen and (max-width 1200px)
  .main-nav__list

    li
      margin-right: 15px;


  .advokat-name

    p
      font-size: 35px;
      line-height: 80px;

  .advokat
    .container
      padding 25px 5px 25px 10px

  .advokat-logo
    display none


@media screen and (max-width 1150px)
  .advokat-name
    width 100%

  .advokat-contacts
    width 100%
    text-align: center;

  .main-nav__list

    li
      margin-right: 5px;

  .contacts-content
    padding: 25px;
    width: 37%;

  .contact-item__one
    font-size: 20px;

  .contact-item
    font-size: 20px;

@media screen and (max-width 1050px)
  .advokat
   text-align center

  .main-nav__list

    li
      margin-right: 0px;

  .nav-mobile
    display block

  .main-nav__list
    position: absolute
    right: -900px
    z-index 2
    background-color #f1f1f1
    width 100%
    max-width 320px
    padding: 20px 0 20px 30px;
    top: 80px
    transition all linear .8s

    li
      display: block
      margin-bottom 30px

      &:last-child
        margin-bottom 0

  .nav-mobile
    top 0
    bottom 3px

@media screen and (max-width 900px)
  .contact-item
    font-size: 16px;

  .contacts-content
    width: 35%;

@media screen and (max-width 850px)

  .main-nav__list

    li
      margin-right 15px


  .advokat-name

    p
      font-size: 30px;
      line-height: 70px;

  .advokat-logo
    display none

  .advokat-tel, .advokat-mail
    font-size: 19px;
    padding-top: 30px;
    padding-right: 15px;

  .advokat-name__services
    font-weight: 300;
    font-size: 25px !important;
    line-height: 55px !important;

@media screen and (max-width 800px)
  .contact-item
    .icon
      width: 18px;
      height: 18px;

  .contact-item
    padding-left: 20px;

  .contacts-content
    width: 40%

@media screen and (max-width 750px)
  .advokat-name_need
    font-size: 30px !important;

  .advokat-name__name
    font-size: 30px !important;

  .advokat-name__about
    font-size: 25px !important;


  .advokat-name__services
    font-size: 25px !important;

  .nav-mobile
    display block

  .main-nav__list
    position: absolute
    right: -900px
    z-index 2
    background-color #f1f1f1
    width 100%
    max-width 320px
    padding 45px 0 45px 30px
    transition all linear .8s

    li
      display: block
      margin-bottom 40px

      &:last-child
        margin-bottom 0

  .criminal-law__pic
    width: 200px;
    height: 200px;

  .civil-law__pic
    width: 200px;
    height: 200px;

  .family-law__pic
    width: 200px;
    height: 200px;

  .arbitration-law__pic
    width: 200px;
    height: 200px;

  .services-main__items
    padding: 15px;
    margin-bottom: 30px;

  .small-section-title
    font-size: 45px;

  .small-section-callus
    margin-top: 95px;
    font-size: 25px;

  h1.services-main__title
    font-size: 34px;

@media screen and (max-width 700px)
  .advokat
    .container
      padding: 0 0 5px 0

  .advokat-name
    text-align: center;
    p
      font-size: 25px;
      line-height: 35px;

  .advokat-tel, .advokat-mail
    font-size: 16px;
    padding-top: 25px;
    padding-right: 10px;

  .advokat-tel .icon, .advokat-mail .icon
    width: 15px;
    height: 15px;
    margin-right: 3px;

  .advokat
    padding: 130px 0

  .about-us-main__info
    padding: 50px;
    line-height: 28px;

  .advokat-name__services
    font-weight: 300;
    font-size: 20px !important;
    line-height: 50px !important;

  .main-nav__list
    position: absolute;
    right: -900px;
    z-index: 2;
    background-color: #f1f1f1;
    width: 100%;
    max-width: 320px;
    padding: 20px 0 20px 30px;
    -webkit-transition: all linear 0.8s;
    -o-transition: all linear 0.8s;
    transition: all linear 0.8s;

  .main-nav__list li
    display: block;
    margin-bottom: 20px;

@media screen and (max-width 660px)
  .contacts-content
    width: 50%;

  footer
    font-size: 16px;

@media screen and (max-width: 650px)
  .services-main__items
    flex-direction: column;

  .small-section-title
    font-size: 40px;

  .small-section-callus
    margin-top: 95px;
    font-size: 20px;

  .contacts-main__map
    display none

  .contacts-content
    width: 100%
    position: relative
    left: 0
    top: 35px;
    height: 340px;

  .contact-item
    font-size: 22px;

  .container
    flex-wrap wrap

  .footer-advokat
    flex-basis: auto;
    width: 100%;
    text-align: center;
    order 2
    margin-top: 10px
    font-size: 16px;

  .developer
    flex-basis: auto;
    width: 100%;
    text-align: center;
    order 1
    font-size: 18px;



@media screen and (max-width: 550px)
  .contact-item
    font-size: 20px;

  .advokat-name__services
    font-weight: 300;
    font-size: 18px !important;
    line-height: 25px !important;

@media screen and (max-width: 500px)
  .small-section
    padding: 40px 0;

  h1.services-main__title
    line-height: 35px;

  .g-btn__practice
    color: #000;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #daa520;
    padding: 0.5em 0.5em;
    outline: none;
    margin-left: 30px;

  .g-btn__achievements
    color: #000;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #daa520;
    padding: 0.5em 0.5em;
    outline: none;

  .about-us-main__info
    padding: 25px;
    line-height: 25px;

  .advokat-name
    p
      font-size: 20px;
      line-height: 50px;

  .criminal-law__info
    margin-top: 10px;

  .civil-law__info
    margin-top: 10px

  .family-law__info
    margin-top: 10px

  .arbitration-law__info
    margin-top: 10px

  .criminal-law__pic
    width: 130px;
    height: 130px;

  .civil-law__pic
    width: 130px;
    height: 130px;
    background: url(../img/general/civil.jpg) -60px 0 no-repeat;
    background-size: cover;

  .family-law__pic
    width: 130px;
    height: 130px;

  .arbitration-law__pic
    width: 130px;
    height: 130px;

  .small-section-title
    font-size: 35px;

  .small-section-callus
    margin-top: 95px;
    font-size: 17px;

  .contacts-content
    width: 100%

  .advokat-name__services
    font-weight: 300;
    font-size: 18px !important;
    line-height: 40px !important;

  .header-logo

    a
      font-size 20px



@media screen and (max-width 450px)
  .small-section-title
    font-size: 27px;

  .small-section-callus
    margin-top: 95px;
    font-size: 14px;

  .about-us-main__info
    padding: 20px;
    font-size: 19px;


  .header-logo a
    font-size: 18px;

  .nav-mobile
    top: 0;
    bottom: 0px;

  .contacts-content
    width: 98%;
    position: relative;
    left: 5px;
    top: 30px;
    height: 330px;

  .contact-item
    font-size: 19px;

  footer
    font-size: 13px;

  .footer-advokat
    font-size: 14px;

  .developer
    font-size: 17px;

  .main-logo
    width: 80px;
    height: 80px;
    background: url(../img/general/logomain.jpg) 15px 11px no-repeat;
    background-size: 50px;

  h1.services-main__title
    font-size: 25px;

@media screen and (max-width 400px)
  .nav-mobile
    width 55px

  .advokat-name_need
    margin-bottom 5px

  .main-nav__list

  .header-logo
    font-size: 19px;

  .advokat-name
    p
      font-size: 19px;
      line-height: 25px;

  .advokat-tel, .advokat-mail
    font-size: 17px;
    padding-top: 20px;
    padding-right: 10px;

  .advokat-tel .icon, .advokat-mail .icon
    width: 10px;
    height: 10px;
    margin-right: 2px;

  .criminal-law__title
    font-size: 21px;

  .criminal-law__text
    font-size: 17px

  .civil-law__title
    font-size: 21px;

  .civil-law__text
    font-size: 17px

  .family-law__title
    font-size: 21px;

  .family-law__text
    font-size: 17px

  .arbitration-law__title
    font-size: 21px;

  .arbitration-law__text
    font-size: 17px

  .services-main__title
    font-size: 35px

  .about-us-main__title
    font-size 35px

  .contacts-title
    font-size 35px

  .header-logo a
    font-size: 13px;

@media screen and (max-width 360px)
  .advokat-name_need
    font-size: 25px !important;
    margin-bottom 5px

  .advokat-name__name
    font-size: 25px !important;

  .small-section-title
    font-size: 21px;

  .small-section-callus
    margin-top: 95px;
    font-size: 10px;



@media screen and (max-width 350px)
  footer
    font-size: 12px;