@font-face
  font-family: "RobotoRegular"
  src : url("../fonts/RobotoRegular.ttf")

@font-face
  font-family: "RobotoBold"
  src : url("../fonts/RobotoBold/RobotoBold.ttf")

@font-face
  font-family: "RobotoLight"
  src : url("../fonts/RobotoLight/RobotoLight.ttf")

@font-face
  font-family: "RobotoMedium"
  src : url("../fonts/RobotoMedium/RobotoMedium.ttf")
