.contacts
  background white
  font-family RobotoLight
  padding: 60px 0px

#contacts-map
  position: relative
  width 100%
  box-shadow: 7.071px 7.071px 35px 7px rgba(0, 0, 0, 0.1)

.contacts-main
  width: 100%
  margin-bottom: 55px;



.contacts-content
  absolute($top:106px,$left:5px)
  background: white
  z-index 1
  padding 30px
  width: 34%;
  height: 390px;
  box-shadow: 7.071px 7.071px 35px 7px rgba(0, 0, 0, 0.1)


.contact-item
  position relative
  padding-left 30px
  margin-bottom 60px
  font-size: 24px;

  &:last-child
    margin-bottom 0

  .icon
    absolute($top:0,$left:0)
    size(25px)

  span
    display: block
    margin-bottom 10px

.contact-item__one
  margin-bottom 125px

