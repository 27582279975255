.advokat
  padding 175px 0
  border-bottom: 2px solid #000
  background url('../img/general/background5.jpg') 0 0 no-repeat
  background-size cover
  .container
    display flex
    flex-wrap wrap
    justify-content space-around
    background #f1f1f1
    opacity 0.85
    padding: 30px 5px 30px 15px
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.52)

.advokat-name,
.advokat-cantacts
  flex-basis auto
  //width 50%

.advokat-name
  width 70%
  p
    font-size: 40px;
    line-height: 65px;

.advokat-contacts
  width 30%

.advokat-name__name
  font-weight 800
  color #daa520

.advokat-name__services
  font-weight 300
  font-size: 27px !important;
  line-height: 45px !important;

.advokat-tel,
.advokat-mail
  font-size 22px
  padding-right 25px



  .icon
    size(20px)
    margin-right 5px

.advokat-tel
  padding-top: 20px

.advokat-mail
  padding-top: 42px

.advokat-logo
  position: relative;
  top: 80px;
  left: 75px;
  width: 209px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  background: url(../img/general/shield.jpg) 14px 15px no-repeat;
  background-size: 165px;

.advokat-name__about
  font-size: 27px!important
  line-height: 45px!important
  margin-bottom 30px

.advokat-name__hello
  margin-bottom 25px

.advokat-name_need
  font-size 35px!important

.advokat-name__name
  margin-bottom 30px
  font-size 35px!important
